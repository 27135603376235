import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ArrowRight from '../../assets/svgicons/ArrowRight';
import { formatDateSecondary, formattedDateText } from '../../utils/dateFormatUtils';
import BackRightBtn from '../../assets/svgicons/BackRightBtn';
import ScanSVG from '../../assets/svgicons/ScanSVG';
import NoteSVG from '../../assets/svgicons/NoteSVG';
import { formatNumber } from '../../utils/string';
import CopySVG from '../../assets/svgicons/copySVG';
import CSLPlasmaText from '../../assets/images/CSLPlasma.text.webp'
import CarouselComponent from '../container/carousel/CarouselComponent';
import { useRef, useState } from 'react';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { MdArrowBackIos, MdArrowForwardIos, MdNavigateNext, MdOutlinePrint } from 'react-icons/md';
import { TfiAngleDoubleRight } from 'react-icons/tfi';
import QrCodeGenerator from '../qrcode/QRCodeComponent';
import { RiDownload2Line } from 'react-icons/ri';
import * as htmlToImage from "html-to-image";
import { ClassComponent } from '../print/PrinfComponent';
import CSLLogo from '../../assets/images/CSLPlasmalogo_white.webp'
import ImageNo from '../../assets/images/imageNo.png'
import LoadingOverlay from '../container/loading/LoadingOverlay';
import { message } from 'antd';
import IpadContent from "../../assets/images/ipad2.png";
import FormsPreviewCardComponent from './FormsPreviewCardComponent';
import "./PreviewCreateCardComponent.css"
import { IconNext } from './IconNext';


function PreviewDownloadCardComponent({ data, handleDetail, handleCopy, isDownloadNo, isPrintNo, isCreate, 
    slideIndex, setslideIndex, codeLang
}) {
    // const [slideIndex, setslideIndex] = useState(0)
    const [loading, setLoading] = useState(false);
    let description = data && data.description ? data.description.split('\n') : []
    if(codeLang === 'es' && data.eventDescriptionES) {
        description = data && data.eventDescriptionES ? data.eventDescriptionES.split('\n') : []
    } else {
        description = data && data.description ? data.description.split('\n') : []
    }
    let dataLabel = {
        eventDate: codeLang === 'es' ? "Fecha del evento" : "Event date",
        scanNow: codeLang === 'es' ? "escanear código ahora" : "SCAN NOW TO WIN"
    }

    let _RenderImage = (qrCodeRef1, isText) => {
        return <div className={``}
            style={{
                background: qrCodeRef1 ? "#fff" : '',
                marginTop: qrCodeRef1 ? "" : '',
            }}
            ref={qrCodeRef1 ? qrCodeRef1 : null}
        >
            <div className='row event-content'>
                <div className='col-7'>
                    <div className='left-section'>
                        <div>
                            <div>
                                {
                                    isCreate && description && description.length === 0 ?
                                        <div className='input-event-layout'>
                                            <p>
                                                Please input event
                                                <br /> description here
                                            </p>
                                        </div> : description && description.map((dc, i) =>
                                            <Card.Text className='input-event-value notes_title text-start m-0'>
                                                {dc}
                                            </Card.Text>)
                                }
                            </div>
                            <div className='underlined-red mt-1'
                            ></div>
                        </div>
                        <div className='row'>
                            <div className='col-8'>
                                <div className='w-100 h-100 d-flex flex-column gap-2'>
                                    <Button className='event-time-btn' style={{ marginBottom: "0.3rem" }} variant="danger">{dataLabel.eventDate}</Button>
                                    <div class="d-flex flex-column gap-1">
                                        {isCreate && !data?.startDate ? <div className='w-100'>
                                            <div className='input-time-layout'>
                                                <span>{dataLabel.eventDate}</span>
                                            </div>
                                        </div> :
                                            <Card.Text style={{ fontSize: "0.9rem" }} className='display-text text-start m-0 mt-2 text-transform-capitalize'>
                                                {`( ${formattedDateText(data && data.startDate, codeLang)}`}
                                            </Card.Text>}
                                        <Card.Text style={{ color: "red" }} className='display-text text-center m-0'>
                                                <span style={{ color: "red" }}><IconNext /></span>
                                                <span style={{ color: "red" }}><IconNext /></span>
                                        </Card.Text>
                                        {isCreate && !data?.endDate ? <div className='w-100 justify-content-end d-flex'>
                                            <div className='input-time-layout'>
                                                <span style={{ opacity: "0.1" }}>{dataLabel.eventDate}</span>
                                            </div>
                                        </div> : <Card.Text style={{ fontSize: "0.9rem" }} className='display-text text-end m-0 text-transform-capitalize'>
                                            {`${formattedDateText(data && data.endDate, codeLang)} )`}
                                        </Card.Text>}
                                        <Card.Text className='display-text flex-row align-items-center gap-2 text-center m-0 d-flex mt-2 justify-content-between'>
                                            <span>
                                                <span style={{ color: "red" }}><IconNext /></span>
                                                <span style={{ color: "red" }}><IconNext /></span>
                                                <span style={{ color: "red" }}><IconNext /></span>
                                                <span style={{ color: "red" }}><IconNext /></span>
                                            </span>
                                            <span className='text-transform-capitalize-scan' style={{paddingTop: "2px"}}>{`${dataLabel.scanNow}`}</span>
                                            <span>
                                                <span style={{ color: "red" }}><IconNext /></span>
                                                <span style={{ color: "red" }}><IconNext /></span>
                                                <span style={{ color: "red" }}><IconNext /></span>
                                                <span style={{ color: "red" }}><IconNext /></span>
                                            </span>
                                        </Card.Text>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4 p-0 d-flex align-items-end'>
                                <QrCodeGenerator
                                    urlQr={data && data.formLink}
                                    isNoFrom={true}
                                    isNodownload={true}
                                    isBorderQr="border-qr"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-5'>
                    <div className='csl-image-layout'>
                        {qrCodeRef1 ? <div className='h-100'
                        >
                            <img className='csl-logo'
                                style={{ height: "100%", width: "100%", objectFit: "contain" }}
                                src={data && data.images && data.images.length > 0 ? data.images[slideIndex].url : []} />
                        </div> : isCreate && data && data.images && data.images.length === 0 ? <div className='h-100 w-100'>
                            <img className='csl-logo' style={{ height: "100%", width: "100%", cursor: "pointer", objectFit: "contain", border: "1px dashed rgb(212, 212, 212)", borderRadius: "5px" }} title='' src={ImageNo} alt={""} />
                        </div> :
                            <CarouselComponent
                                width="100%"
                                height="100%"
                                data={data && data.images ? data.images : []}
                                slideIndex={slideIndex}
                                handleCurrentSlide={(value) => {
                                    setslideIndex && setslideIndex(value)
                                }}
                                isText={isText}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    let _RenderImage1 = (qrCodeRef1, isText) => {
        return <div className={`${qrCodeRef1 ? "hw-1290-1080- w-100 h-100" : "w-100"} m-auto preview-layout`}
            style={{
                background: qrCodeRef1 ? "#fff" : '',
                // width: qrCodeRef1 ? "1920" : "",
                // height: qrCodeRef1 ? "1080" : ""
            }}
            ref={qrCodeRef1 ? qrCodeRef1 : null}
        >
            <div className='red-box-cut'>
                {/* <h1 className='h1-26px'>CSL Plasma</h1> */}
                <img className='csl-logo' 
                // style={{ height: "3rem", marginLeft: "2.3rem" }} 
                src={CSLLogo} />
            </div>

            <div className='preview-content'>
                <div className='row mt-1 h-100 '>
                    <div className='col-8 h-100 d-flex flex-column gap-4'>
                        <div className='row'>
                            <div className='col-12'>
                                {
                                    description && description.map((dc, i) =>
                                        <Card.Text style={{ fontSize: "26px" }} className='notes_title text-start m-0'>
                                            {dc}
                                        </Card.Text>)
                                }
                                <div className='border-red' style={{ marginTop: "1rem" }}></div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-8 div-content-flex'>
                                <div className='w-100'>
                                    <Card.Text style={{ fontSize: "14px", justifyContent: "space-between" }} className='notes_title text-center m-0 d-flex mt-1 gap-2 justify-content-between'>
                                        <span className='text-wrap-cus'>
                                            <span style={{ color: "red" }}><IconNext /></span>
                                            <span style={{ color: "red" }}><IconNext /></span>
                                            <span style={{ color: "red" }}><IconNext /></span>
                                            <span style={{ color: "red" }}><IconNext /></span>
                                        </span>
                                        <span className='text-transform-capitalize-scan text-wrap-cus' style={{ fontSize: "14px", paddingTop: "2px" }}>{`${dataLabel.scanNow}`}</span>
                                        <span className='text-wrap-cus'>
                                            <span style={{ color: "red" }}><IconNext /></span>
                                            <span style={{ color: "red" }}><IconNext /></span>
                                            <span style={{ color: "red" }}><IconNext /></span>
                                            <span style={{ color: "red" }}><IconNext /></span>
                                        </span>
                                    </Card.Text>
                                </div>
                                <div className='d-flex flex-column' style={{ gap: '10px' }}>
                                    <div className='w-100'>
                                        <Button className='btn-red text-transform-capitalize text-nowrap' variant="danger">{dataLabel.eventDate}</Button>
                                    </div>
                                    <div className='w-100'>
                                        <Card.Text className='date_title text-start m-0 text-transform-capitalize'>
                                            {`( ${formattedDateText(data && data.startDate, codeLang)}`}
                                        </Card.Text>
                                        <Card.Text style={{ fontSize: "14px", color: "red", marginTop: "5px", marginBottom: "5px" }} className='notes_title text-center m--0 mt-1- mb-1-'>
                                            <span style={{ color: "red" }}><IconNext /></span>
                                            <span style={{ color: "red" }}><IconNext /></span>
                                        </Card.Text>
                                        <Card.Text className='date_title text-end m-0 text-transform-capitalize'>
                                            {`${formattedDateText(data && data.endDate, codeLang)} )`}
                                        </Card.Text>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4 p-0 d-flex justify-content-end'>
                                <QrCodeGenerator
                                    urlQr={data && data.formLink}
                                    isNoFrom={true}
                                    isNodownload={true}
                                    isBorderQr="border-qr"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-4 d-flex align-items-center justify-content-center  h-100 '>
                        <div className='w-100 h-100'>
                            {/* <CarouselComponent
                                data={data && data.images ? data.images : []}
                                slideIndex={slideIndex}
                                handleCurrentSlide={(value) => {
                                    setslideIndex && setslideIndex(value)
                                }}
                                isText={isText}
                            /> */}
                            {qrCodeRef1 ? <div className='h-100'
                            //  style={{
                            //     height: "247px",//'7rem',
                            //     width: "247px",
                            // }}
                            >
                                {/* 1144 × 1144 */}
                                <img className='csl-logo'
                                    key={Date.now()}
                                    style={{ height: "247px", width: "247px", minHeight: "247px", minWidth: "247px", objectFit: "contain" }}
                                    src={data && data.images && data.images.length > 0 ? data.images[slideIndex].url : []} />
                            </div> : <CarouselComponent
                                data={data && data.images ? data.images : []}
                                slideIndex={slideIndex}
                                handleCurrentSlide={(value) => {
                                    setslideIndex && setslideIndex(value)
                                }}
                                isText={isText}
                            />}
                        </div>
                    </div>
                </div>
            </div>

            <div className={`w-100 ${qrCodeRef1 ? "border-radiusNew" : "border-radius"}`} />
        </div>
    }

    const qrCodeRef1 = useRef(null);
    const qrCodeRef11 = useRef(null);
    const qrCodeRef2 = useRef(null);
    const downloadQRCode1 = () => {
        try {
            setLoading(true)
            htmlToImage
                .toPng(qrCodeRef1.current,
                    // { width: 1020, height: 880 }
                    { width: 688, height: 397 }
                )
                .then(function (dataUrl) {
                    const link = document.createElement("a");
                    link.href = dataUrl;
                    link.download = `${data && data.eventName ? `${data.eventName}-qr` : "qr-code"}.png`;
                    link.click();
                    setLoading(false)
                    message.success(`Download complete`)
                })
                .catch(function (error) {
                    message.error(`Fail download event preview`)
                    setLoading(false)
                    console.error("Fail download event preview:", error);
                });
        } catch (error) {
            setLoading(false)
            message.error(`Fail download event preview`)
            console.error(" Fail download event preview:", error);
        }
    };

    const _DownloadQr = async () => {
        if (qrCodeRef1.current) {
            try {
                setLoading(true);
                const canvas = await htmlToImage.toCanvas(qrCodeRef1.current, { cacheBust: true, pixelRatio: 10 });
                const link = document.createElement("a");
                link.href = canvas.toDataURL("image/png");
                link.download = `${data && data.eventNumber ? `${data.eventNumber}` : "qr-code"}.png`
                link.click();
                setLoading(false);
                message.success(`Download complete`)
            } catch (error) {
                setLoading(false);
                console.error("Failed to capture image for downloading:", error);
                message.error('Failed to download');
            }
        }
    };

    let printComponentRef = useRef(null)

    const handlePrint = () => {
        printComponentRef.current.handlePrint()
    }

    return (
        <div className='w-100 preview-pad'>
            {/* <FormsPreviewCardComponent>
                {
                    _RenderImage(null, "")
                }
                {loading ? <LoadingOverlay /> : null}
            </FormsPreviewCardComponent> */}

            <div className='w-100-'
                ref={qrCodeRef11}
                style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
            >
                {_RenderImage1(qrCodeRef1, "")}
            </div>
            <ClassComponent
                reportResult={_RenderImage1(qrCodeRef2, "")}
                ref={printComponentRef}
            />

            <div className='row mt-2'>
                {isDownloadNo ? "" : <div className='col-12 d-flex'>
                    <button style={{ width: "10rem", opacity: loading ? "0.3" : "1" }} className='btn-download me-2'
                        onClick={_DownloadQr}
                    >
                        <span className="me-1"><RiDownload2Line /></span><span>Download Flyer ({codeLang ? codeLang.toUpperCase() : ""})</span>
                    </button>
                </div>}
                {/* {isPrintNo ? "" : <div className='col-6 text-end'>
                    <button style={{ width: "11rem" }} className='btn-download' onClick={handlePrint}>
                        <span className="me-1"><MdOutlinePrint /></span><span>Print Event Page</span>
                    </button>
                </div>} */}

            </div>
        </div>
    );
}

export default PreviewDownloadCardComponent;