import { Button, Card, Form } from "react-bootstrap";
import NavItemNavigation from "../../components/navigation/NavItemNavigation";
import React, { useEffect, useRef, useState } from "react";
import CardComponent from "../../components/card/CardComponent";
import { useSelector } from "react-redux";
import InputSelection from "../../components/container/inputField/inputSelection";
import InputDate from "../../components/container/inputField/inputDate";
import InputFieldSearch from "../../components/container/inputField/inputFieldSearch";
import timeUtils from "../../utils/timeUtils";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BackLeftBtn from "../../assets/svgicons/BackLeftBtn";
import BackComponent from "../../components/container/backComponent/BackComponent";
import EventCardComponent from "../../components/card/EventCardComponent";
import TableComponent from "../../components/table/TableComponent";
import EventPreviewModal from "../../components/container/modal/queueModal/EventPreviewModal";
import { AiOutlineExport } from "react-icons/ai";
import { Col, Input, Radio, Select, Space, message } from "antd";
import QrCodeGenerator from "../../components/qrcode/QRCodeComponent";
import PreviewCardComponent from "../../components/card/PreviewCardComponent";
import ipad from "../../assets/images/ipad1.png";
import UploadComponent from "../../components/container/upload/UploadComponent";
import FormsPreviewCardComponent from "../../components/card/FormsPreviewCardComponent";
import FormsPreviewPhoneComponent from "../../components/card/FormsPreviewPhoneComponent";
import API from '../../utils/request'
import { API_ENDPOINT_CSL_PLASMA, API_ENDPOINT_LOCATION } from "../../config/api";
import * as Storage from '../../utils/storage';
import { storageKeys } from "../../constants/storage";
import { CheckRoleType } from "../../utils/checkRole";
import { EVENT_CREATE_URL, EVENT_GET_URL, EVENT_UPDATE_URL } from "../../config/urls";
import LoadingOverlay from "../../components/container/loading/LoadingOverlay";
import { FaArrowRight } from "react-icons/fa";
import BackRightBtn from "../../assets/svgicons/BackRightBtn";
import MessageModalMain from "../../components/container/modal/modalMessage/messageModalMain";
import SelectMultiCustom from "../../components/select/SelectMultiCustom";
import SelectComponent from "../../components/select/SelectComponent";
import { CenterObject } from "../../utils/checkCenterLocation";
import PreviewCreateCardComponent from "../../components/card/PreviewCreateCardComponent";
import PreviewCreateCardComponentNew from "../../components/card/PreviewCreateCardComponentNew";
import CustomInput from "../../components/input/CustomInput";

const EventCreatePage = () => {
    const fixedTextDescription = "Ask us how you can win";
    const fixedTextDescriptionES = "¡Pregúntanos cómo ganar!";

    const user = useSelector((state) => state.authReducer.user);
    var users = Storage.getJson(storageKeys.USER);
    let userUse = user ? user : users
    const [loading, setLoading] = useState(false);
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const [eventId, setEventId] = useState(searchParams.get('event') ? searchParams.get('event') : '')
    let isCopy = location && location.state && location.state.isCopy ? location.state.isCopy : false
    const [centerId, setCenterId] = useState(userUse && userUse?.role === "center_staff" ? [userUse?.centerId] : [])
    const [currentPositionKey, setCurrentPositionKey] = React.useState("current");
    const EventPreviewModalRef = useRef("")
    const [tabPosition, setTabPosition] = useState('pad');
    const [dataItem, setDataItem] = useState({
        // _id: "",
        scanCount: 0,
        submissionCount: 0,
        eventName: "",
        eventNameES: "",
        eventNumber: "",
        eventCenter: "",
        startDate: null,
        endDate: null,
        description: ``,
        eventDescriptionES: '',
        images: [
            // {
            //     "size_type": "bytes",
            //     "size_img": 19383,
            //     "size": 19383,
            //     "name": "DonationTips.png",
            //     url: "https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/MTJV3?wid=1144&hei=1144&fmt=jpeg&qlt=90&.v=1694014871985",
            // },
        ],
        formLink: `${API_ENDPOINT_CSL_PLASMA}/ShareFormPage`
    })
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false)
    const messageModalRef = useRef('rememberMe');   

    const [centerData, setCenterData] = useState([])

    const getDataCenter = async () => {
        try {
            API.post(API_ENDPOINT_LOCATION, {
                "serviceName": "location",
                "serviceType": "getSavableLocations"
            })
                .then((res) => {
                    if (res.status === 200) {
                        setCenterData(res.locations ? res.locations.map(it => ({
                            ...it,
                            label: `CSL Plasma, ${it.name}`,
                            value: it.locationID
                        })) : [])
                    }
                })
                .catch((err) => {
                })
        } catch (error) {
        }
    }
    useEffect(() => {
        getDataCenter()
    }, []);

    const getDataEventDetail = async () => {
        try {
            setLoading(true)
            API.get(`${EVENT_GET_URL}/${eventId}`)
                .then((res) => {
                    if (res.status === 1 && res.data) {
                        let data = res.data
                        setCenterId(userUse.role === "center_staff" ? [userUse.centerId] : data.appliedCenter ? data.appliedCenter : [])
                       
                        setDataItem({
                            ...data,
                            _id: data._id,
                            eventId: data.eventId,
                            scanCount: data.scannedCount,
                            submissionCount: data.submissionCount,
                            eventName: data.eventName,
                            eventNameES: data.eventNameES ? data.eventNameES : "",
                            eventNumber: isCopy ? '' : data.eventNumber,
                            eventCenter: userUse.role === "center_staff" ? [userUse.centerId] : data.appliedCenter,
                            startDate: data.startDate,
                            endDate: data.endDate,
                            description: data.eventDescription ? data.eventDescription.replace(`${fixedTextDescription}\n`, "") : ``,
                            eventDescriptionES: data.eventDescriptionES ? data.eventDescriptionES.replace(`${fixedTextDescriptionES}\n`, "") : ``,
                            images: data.eventGiftPicture ? data.eventGiftPicture.map(it => ({...it, size: it.size_img})) : [],
                            formLink: `${API_ENDPOINT_CSL_PLASMA}/ShareFormPage?id=${data.formsLink && data.formsLink.id && data.formsLink.id}`
                        })
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                })
        } catch (error) {
            setLoading(false)
            message.error('Internal Server Error!')
        }
    }
    useEffect(() => {
        if (userUse && userUse?.role === "center_staff" ? userUse?.centerId : null) {
            eventId && getDataEventDetail()
        } else if (userUse && userUse?.role === "field_marketing") {
            eventId && getDataEventDetail()
        }
    }, [eventId, userUse]);

    let centerDataNew = []

    if(userUse && userUse?.role === "center_staff") {
        centerDataNew = centerData.filter(it => it.locationID === userUse?.centerId)
    } else {
        centerDataNew = centerData
    }

    const validationError = () => {
        if(!dataItem.eventName || !dataItem.startDate || !dataItem.endDate || centerId.length === 0 ||
            !dataItem.description || !dataItem.images || dataItem.images && dataItem.images.length === 0
         ) {
            return true
        }
        return false
    }
    
    const getparams = () => {
        let params = {
            "eventName": dataItem.eventName,
            "eventNameES": dataItem.eventNameES,
            "eventNumber": dataItem.eventNumber,
            "appliedCenter": centerId,
            "startDate": dataItem.startDate,
            "endDate": dataItem.endDate,
            "eventDescription": `${fixedTextDescription}\n${dataItem.description}`,
            "eventDescriptionES": (`${fixedTextDescriptionES}\n${dataItem.eventDescriptionES !== "" ? dataItem.eventDescriptionES : dataItem.description}`),
            "eventGiftPicture": dataItem.images ? dataItem.images.map(it => ({
                "url": it.url,
                "size_type": it.size_type,
                "size_img": it.size,
                "name": it.name ? it.name : ""
            })) : []
        }
        return params
    }
    
    const handleCreate = async() => {
        try {
            setIsSubmit(true)
            if(validationError()) {
                return true
            }
            setLoading(true)
            let params = getparams();
            API.post(EVENT_CREATE_URL, params)
                .then(async (res) => {
                    setLoading(false)
                    if (res.status === 1) {
                        setIsSubmit(false)
                        messageModalRef.current.showSuccess(res.message, () => {
                            navigate(`/event`,
                                { state: {}, replace: true }
                            );
                        })
                    } else {
                        messageModalRef.current.showWarning(res.message);
                    }
                })
                .catch((err) => {
                    messageModalRef.current.showWarning(err?.message);
                    setLoading(false)
                })
        } catch (error) {
            setLoading(false)
            message.error('Internal Server Error!')
        }
    }

    const handleEdit = async() => {
        try {
            setIsSubmit(true)
            if(validationError()) {
                return true
            }
            setLoading(true)
            let params = getparams();
            API.post(`${EVENT_UPDATE_URL}/${eventId}`, params)
                .then(async (res) => {
                    setLoading(false)
                    if (res.status === 1) {
                        setIsSubmit(false)
                        messageModalRef.current.showSuccess(res.message, () => {
                            navigate(`/event`,
                                { state: {}, replace: true }
                            );
                        })
                    } else {
                        messageModalRef.current.showWarning(res.message);
                    }
                })
                .catch((err) => {
                    messageModalRef.current.showWarning(err?.message);
                    setLoading(false)
                })
        } catch (error) {
            setLoading(false)
            message.error('Internal Server Error!')
        }
    }

    let isEdit = false
    if(dataItem && dataItem._id) {
        if(isCopy) {
            isEdit = false
        } else {
            isEdit = true
        }
    } else {
        isEdit = false
    }

    let isShow = false
    let disabled = true

    return <div className="width-100 m-auto h-100 pt-2-">
        <BackComponent 
        onClick={() => {
            navigate(`/event`,
                { state: { }, replace: true }
            );
        }} 
        onClickCreate={() => {
            navigate(`/event/create?event=${'data._id'}`)
        }}
        title={ isEdit ? "Event Edit" : "Event create"}
        />
        
        <div className="w-100 h-100- mt-2"
        >
            <Card className="h-100 card-cus-">
                <Card.Body className="ps-0 pe-0">
                    
                    <div className="w-100 pt-2 div-form ">
                        <div className="w-100">
                            <div className="w-100 d-flex align-items-baseline" style={{ justifyContent: "space-between" }}>
                                <h5 style={{ fontWeight: "600" }}>Event Setting</h5>
                            </div>

                            <div className="w-100 h-100- mt-3 mb-3" >
                                <div className="row mt-3">
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <div className="text16">Event name  <span class="require_cus">*</span></div>
                                    </div>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        <Input 
                                        status={ isSubmit && !dataItem.eventName ? "error" : ""}
                                         value={dataItem.eventName} onChange={(e) => setDataItem({...dataItem, eventName: e.target.value})} placeholder="Event name " />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <div className="text16">Event name (Spanish)</div>
                                    </div>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        <Input 
                                        // status={ isSubmit && !dataItem.eventNameES ? "error" : ""}
                                         value={dataItem.eventNameES} onChange={(e) => setDataItem({...dataItem, eventNameES: e.target.value})} placeholder="Event name (Spanish)" />
                                    </div>
                                </div>
                                {isEdit ? <div className="row mt-3">
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <div className="text16">Event number <span class="require_cus">*</span></div>
                                    </div>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        <Input 
                                        // status={ isSubmit && !dataItem.eventNumber ? "error" : ""}
                                        disabled={disabled}
                                         value={dataItem.eventNumber} 
                                         onChange={(e) => {
                                            if(disabled) return false
                                            setDataItem({...dataItem, eventNumber: e.target.value})}} 
                                         placeholder="Event number" />
                                    </div>
                                </div> : ''}
                                <div className="row mt-3">
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <div className="text16">Event center <span class="require_cus">*</span></div>
                                    </div>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        <SelectMultiCustom
                                            currentSelect={
                                                centerId.map(ct => ct && CenterObject(ct, centerData))
                                            }
                                            onSelected={(value) => {
                                                setCenterId(value.map(ct => `${JSON.parse(ct).locationID}`))
                                            }}
                                            maxTagCount={"responsive"}
                                            disabled={users.role === "center_staff"}
                                            className={`w-100 ${ isSubmit && centerId.length === 0 ? "ant-select-status-error" : ""}`}
                                            centerData={centerDataNew}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <div className="text16">Event date <span class="require_cus">*</span></div>
                                    </div>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        
                                        <div className="row">
                                            <div className='col-5 pe-0'>
                                                <InputDate
                                                    value={dataItem.startDate}
                                                    status={ isSubmit && !dataItem.startDate ? "error" : ""}
                                                    className="w-100"
                                                    max={dataItem.endDate}
                                                    onChange={(date) => {
                                                        setDataItem({ ...dataItem, startDate: date ? timeUtils.formatData(date) : "" })
                                                    }}
                                                    id="startDate"
                                                    placeholder='Start Date'
                                                    size={`middle`}
                                                />
                                            </div>
                                            <div className='col-2 d-flex justify-content-center'>
                                            <span className='ps-2 pe-2 align-items-center d-flex'><BackRightBtn opacity={"0.4"} width={"1rem"} height={"1rem"} /></span>
                                            </div>
                                            <div className='col-5 ps-0'>
                                                <InputDate
                                                    value={dataItem.endDate}
                                                    status={ isSubmit && !dataItem.endDate ? "error" : ""}
                                                    className="w-100"
                                                    min={dataItem.startDate}
                                                    onChange={(date) => {
                                                        setDataItem({ ...dataItem, endDate: date ? timeUtils.formatData(date) : "" })
                                                    }}
                                                    id="endDate"
                                                    placeholder='End Date'
                                                    size={`middle`}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <div className="text16">Event description  <span class="require_cus">*</span></div>
                                    </div>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        <CustomInput 
                                            onChange={(e) => setDataItem({...dataItem, description: e.target.value})}
                                            fixedText={fixedTextDescription}
                                            isSubmit={isSubmit}
                                            placeholder={"Please add prize description here"}
                                            value={dataItem.description}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <div className="text16">Event description (Spanish)</div>
                                    </div>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        <CustomInput 
                                            onChange={(e) => setDataItem({...dataItem, eventDescriptionES: e.target.value})}
                                            fixedText={fixedTextDescriptionES}
                                            isSubmit={false}
                                            placeholder={"Please add prize description here (Spanish)"}
                                            value={dataItem.eventDescriptionES}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <div className="text16">Event gift picture <span class="require_cus">*</span></div>
                                    </div>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        <div className="w-100">
                                            <UploadComponent 
                                            uploadFile={dataItem && dataItem.images ? dataItem.images : []} setUploadFile={(values) => {
                                                setDataItem({...dataItem, 
                                                    images: [...values]
                                                })
                                            }} 
                                            className={isSubmit && !dataItem.images || isSubmit && dataItem.images && dataItem.images.length === 0 ? "cus-error" : ``}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <div className="text16">Forms link</div>
                                    </div>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                       <div>
                                       <QrCodeGenerator
                                            urlQr={dataItem && dataItem.formLink}
                                            className="w-100"
                                            isCreate={true}
                                        />
                                       </div>
                                    </div>
                                </div>
                                
                                {isShow && <>
                                <div className="row mt-3">
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                        <div className="w-100 d-flex align-items-baseline" style={{ justifyContent: "space-between" }}>
                                            <h5 style={{ fontWeight: "600" }}>Event Preview</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className='col-sm-12 col-md-12 col-lg-12'>
                                        <div 
                                        className="w-100 m-auto div-create-preview" 
                                        // className="w-100 ps-sm-1 ps-md-5 ps-lg-5 pe-sm-1 pe-md-5 pe-lg-5 m-auto" 
                                        style={{maxWidth: "55rem"}}
                                        // style={{maxWidth: "1920px", maxHeight: "34rem"}} 
                                        >
                                            {/* <PreviewCreateCardComponent
                                                data={dataItem}
                                                isDownloadNo={true}
                                                isPrintNo={true}
                                                isCreate={true}
                                            /> */}
                                            <PreviewCreateCardComponentNew
                                                data={dataItem}
                                                isDownloadNo={true}
                                                isPrintNo={true}
                                                isCreate={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                </>}

                            </div>

                        </div>
                    </div>
                    
                </Card.Body>
            </Card>
        </div>

        {isShow && <div className="w-100 h-100- mt-3">
            <Card className="h-100 card-cus-">
                <Card.Body className="ps-0 pe-0">
                    
                    <div className="w-100 pt-2 div-form">
                        <div className="w-100">
                            <div className="w-100 d-flex align-items-baseline" style={{ justifyContent: "space-between" }}>
                                <div className="row w-100">
                                    <div className="col-6">
                                        <h5 style={{ fontWeight: "600" }}>Forms Preview</h5>
                                    </div>
                                    <div className="col-6 pe-0 text-end">
                                        <Space
                                            style={{
                                                marginBottom: 24,
                                            }}
                                        >
                                            <Radio.Group value={tabPosition} onChange={(e) => setTabPosition(e.target.value)}>
                                                <Radio.Button value="pad">Pad</Radio.Button>
                                                <Radio.Button value="mobile">Mobile</Radio.Button>
                                            </Radio.Group>
                                        </Space>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="h-100- mt-3 mb-3 div-create-preview" style={{ maxWidth: "55rem", margin:"0px auto" }} >
                                {tabPosition === "pad" ? <div className="row mt-3">
                                    <div className='col-sm-12 col-md-12 col-lg-12'>
                                        <FormsPreviewCardComponent
                                            data={dataItem}
                                            isDownloadNo={true}
                                            isPrintNo={true}
                                            isDevice={'pad'}
                                        />
                                    </div>
                                </div> : ""}
                                {tabPosition == "mobile" ? <div className="row mt-3">
                                    <div className='col-sm-12 col-md-12 col-lg-12'>
                                        <div className="w-100 m-auto"
                                            style={{ maxWidth: "55rem" }}
                                        // style={{maxWidth: "1920px", maxHeight: "34rem"}}
                                        >
                                            <FormsPreviewPhoneComponent
                                                data={dataItem}
                                                isDownloadNo={true}
                                                isPrintNo={true}
                                            />
                                        </div>
                                    </div>
                                </div> : ""}
                            </div>

                        </div>
                    </div>
                    
                </Card.Body>
            </Card>
        </div>}
        

        <div className="w-100 h-100- mt-3" 
        >
            <Button 
            onClick={() => {
                if(isEdit) {
                    handleEdit() 
                } else {
                    handleCreate()
                }
            }}
             className='form-submission-btn- btn-bg-blue' 
            //  disabled={validationError()}
              type="primary" htmlType="submit">
                Submit
            </Button>
        </div>

      <EventPreviewModal 
        ref={EventPreviewModalRef}
      />
      {loading ? <LoadingOverlay /> : null}
      <MessageModalMain textCentered ref={messageModalRef} />
    </div>
}
export default EventCreatePage;